import Projects from "./projects"


export default function App() {
  return (
    <>
      <Projects/>
    </>
  )
}

